import React, { useState, useEffect, useRef, useCallback } from "react";
import '../style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import Constants from "../../../Constants";
import { toaster } from "../../../utils/toast";
import useAxios from "../../../hooks/useAxios";
import XLoader from "../../../components/common/XLoader";
import { parseAndFormatUtcDate } from "../../../Utils";
import HorizontalGraph from "../../admin/dashboard/components/HorizontalGraph";
import DateRangePicker from "../../admin/dashboard/components/DateRangePicker";

const SuperDashboard = () => {
    const currentDate = new Date();
    const navigate = useNavigate();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const [dataPoints, setDataPoints] = useState([]);
    const [from, setFrom] = useState(startOfMonth);
    const [to, setTo] = useState(currentDate);
    const fromDatePickerRef = useRef(null);
    const toDatePickerRef = useRef(null);
    const [timeTaken, setTimetaken] = useState(0);
    const [totalNumCases, setTotalNumCases] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [isOpenUserGraph, setOpenGraphData] = useState(true);
    const [isOpenDateRange, setIsOpenDateRange] = useState(false);
    const [selectedRange, setSelectedRange] = useState({});

    const { postData: companyAnalyticsPostData, isLoading: companyAnalyticsIsLoading } = useAxios()

    const getCompanyAnalytics = useCallback(async () => {
        const { success, data, message } = await companyAnalyticsPostData(Constants.ApiUrl.super.dashboard.companyAnalytics, { selectedRange: selectedRange });

        if (success) {
            console.log(data);
            const { averageTimeTaken, totalCases, topCompanyAnalytic } = data
            setTimetaken(averageTimeTaken)
            setDataPoints(topCompanyAnalytic)
            setTotalNumCases(totalCases)
        } else {
            toaster({ message, success });
        }
    }, [selectedRange, companyAnalyticsPostData]);

    useEffect(() => {
        if (from && to) {
            getCompanyAnalytics()
        }
    }, [selectedRange, getCompanyAnalytics])

    const handleViewAll = () => {
        setIsOpenDateRange(false);
        setSelectedRange({});
    }

    const handleOpenFirmDashboard = (name) => {
        navigate(`/super/firm-dashboard/${name}`)
    }

    return (
        <section className='content-wrapper'>
            <div class="container layout-container case-form">
                <div className="listing">
                    <div className="graphs-wrap">
                        <div className="d-flex justify-content-between align-items-center mb-6">
                            <h1 className="section-title">Dashboard</h1>
                        </div>
                        <div className="row graph-container db-container">
                            <div className="col-md-7">
                                {/* <div className="flex">
                                    <div className="label me-3 f-x-large">Demands generated by</div>
                                    <div className="btn-group graph-tabs" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" onChange={() => setOpenGraphData(!isOpenUserGraph)} className="btn-check" name="graphData" id="btnradio1" autocomplete="off" checked={isOpenUserGraph} />
                                        <label className="btn btn-outline-primary" for="btnradio1">User</label>

                                        <input type="radio" onChange={() => setOpenGraphData(!isOpenUserGraph)} className="btn-check" name="graphData" id="btnradio2" autocomplete="off" checked={!isOpenUserGraph} />
                                        <label className="btn btn-outline-primary" for="btnradio2">Month</label>
                                    </div>
                                </div> */}
                                <div className="graph-container-one d-flex">
                                    {(companyAnalyticsIsLoading && !isOpenUserGraph) && <XLoader />}
                                    <HorizontalGraph dataPoints={dataPoints} totalNumCases={totalNumCases} graph={true} handleOpenFirmDashboard={handleOpenFirmDashboard} />
                                    <div className="d-flex justify-content-between align-items-center w-100 pagination-btm">
                                        <p className="mb-0 text-sm text-default-color">Showing top 10 this month</p>
                                        <div className="graph-actions d-flex align-items-center">
                                            {isOpenDateRange && <a><DateRangePicker onRangeChange={(ranges) => {
                                                setSelectedRange({ ...ranges })
                                            }} /></a>}
                                            {!isOpenDateRange && <a onClick={() => setIsOpenDateRange(!isOpenDateRange)} className="graph-link">Change date range</a>}
                                            <a onClick={() => handleViewAll()} className="graph-link">View All</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-5 page-container">
                                <div className="top-content">Average Demand Creation Time:</div>
                                <div className="center-content"><span>{timeTaken}</span> Minutes</div>
                                <div className="bottom-right-text">{totalNumCases} demands</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SuperDashboard;