export const GET_USERS_REQUESTED = 'GET_USERS_REQUESTED';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILED = 'GET_USERS_FAILED';
export const GET_USERS = 'GET_USERS';
export const SET_USERS = 'SET_USERS';
export const STORED_LIABILTY = 'STORED_LIABILTY';
export const STORED_INJURY_ANALYSIS = 'STORED_INJURY_ANALYSIS';
export const STORED_DAMAGE_ANALYSIS = 'STORED_DAMAGE_ANALYSIS';
export const STORED_PAIN_AND_SUFFERING = 'STORED_PAIN_AND_SUFFERING';
export const STORED_MEDICAL_PROVIDER = 'STORED_MEDICAL_PROVIDER';
export const STORED_ECONOMIC_DAMAGE = 'STORED_ECONOMIC_DAMAGE';
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const STORED_CASE_INFO = 'STORED_CASE_INFO';
export const PRE_PROCCESSED_MEDICAL_DATA = 'PRE_PROCCESSED_MEDICAL_DATA';
export const IS_CASE_SAVED = 'IS_CASE_SAVED'
export const REF_DATA_CAPTURED = 'REF_DATA_CAPTURED'
export const ADD_CASE_FORM_STEP = 'ADD_CASE_FORM_STEP'
export const SHOULD_NEED_TO_PROCESSCASE = 'SHOULD_NEED_TO_PROCESSCASE'
export const ROUTE_NAME = 'ROUTE_NAME'
export const CANCEL_CASE = 'CANCEL_CASE'
export const SUMMARY_LOADING = 'SUMMARY_LOADING'
