import React, { useState, useEffect, useRef, useCallback } from "react";
import '../style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import { useParams } from "react-router-dom";
import HorizontalGraph from "./components/HorizontalGraph";
import VerticalGraph from "./components/VerticalGraph";
import useAxios from "../../../hooks/useAxios";
import Constants from "../../../Constants";
import { toaster } from "../../../utils/toast";
import XLoader from "../../../components/common/XLoader";
import { useSelector } from "react-redux";
import { parseAndFormatUtcDate } from "../../../Utils";
import DateRangePicker from "./components/DateRangePicker";
import { ReactComponent as HelpIcon } from "../../../../src/assets/icons/HelpIcon.svg";


const Dashboard = () => {
    const currentDate = new Date();
    const { firm } = useParams();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const [userAnalyticDataPoint, setUserAnalyticDataPoint] = useState([]);
    const [monthlyAnalyticDataPoint, setMonthlyAnalyticDataPoint] = useState([]);
    const [from, setFrom] = useState(startOfMonth);
    const [to, setTo] = useState(currentDate);
    const fromDatePickerRef = useRef(null);
    const toDatePickerRef = useRef(null);
    const [averageTimeTaken, setAverageTimeTaken] = useState("");
    const [totalNumCases, setTotalNumCases] = useState("");
    const [cardDetailData, setCardDetailData] = useState(null);
    const { subscriptionRenewalDate } = useSelector((state) => state.users?.subscription || {});
    const [isActive, setIsActive] = useState(false);
    const [isActiveAccrued, setIsActiveAccrued] = useState(false);
    const [isActiveCarte, setIsActiveCarte] = useState(false);
    const [selectedRange, setSelectedRange] = useState({});
    const [companyDetail, setCompanyDetail] = useState("");

    const { postData: userAnalyticsPostData, isLoading: userAnalyticsIsLoading } = useAxios()
    const { postData: monthlyPostData, isLoading: monthlyAnalyticsIsLoading } = useAxios()

    const getUserAnalytics = useCallback(async () => {
        const { success, data, message } = await userAnalyticsPostData(Constants.ApiUrl.company.dashboard.userAnalytics, { selectedRange: selectedRange, databaseName: firm ? firm : null });
        if (success) {
            const { topUserAnalytics, caseOverview, companyDetail } = data;
            setUserAnalyticDataPoint(topUserAnalytics);
            setAverageTimeTaken(caseOverview?.averageTimeTaken);
            setCompanyDetail(companyDetail);
            setTotalNumCases(caseOverview?.totalCases);
        } else {
            toaster({ message, success });
        }
    }, [selectedRange, userAnalyticsPostData]);

    const getMonthlyAnalytics = useCallback(async () => {
        const { success, data, message } = await monthlyPostData(Constants.ApiUrl.company.dashboard.monthlyAnalytics, {databaseName: firm ? firm : null});

        if (success) {
            const { last12MonthData, cardDetail } = data;
            console.log(cardDetail)
            setMonthlyAnalyticDataPoint(last12MonthData);
            setCardDetailData(cardDetail)
        } else {
            toaster({ message, success });
        }
    }, [monthlyPostData]);

    useEffect(() => {
        if (from && to) {
            getUserAnalytics()
        }
    }, [selectedRange, getUserAnalytics])

    useEffect(() => {
        getMonthlyAnalytics()
    }, [getMonthlyAnalytics])

    const [isOpenUserGraph, setOpenGraphData] = useState(true);
    const [isOpenDateRange, setIsOpenDateRange] = useState(false);

    const handleViewAll = () => {
        setIsOpenDateRange(false);
        setSelectedRange({});
    }

    return (
        <section className='content-wrapper'>
            <div class="container layout-container case-form">
                <div className="listing">
                    <div className="graphs-wrap">
                        <div className="d-flex justify-content-between align-items-center mb-6">
                            <h1 className="section-title">Dashboard</h1>
                            <p className="mb-0 text-sm text-default-color">Your subscription renews {parseAndFormatUtcDate(companyDetail?.subscriptionRenewalDate)}</p>
                        </div>
                        <div className="row graph-container db-container">
                            <div className="col-md-7">
                                <div className="d-flex align-items-center">
                                    <div className="label me-3 f-x-large">Demands generated by</div>
                                    <div className="btn-group graph-tabs" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" onChange={() => setOpenGraphData(!isOpenUserGraph)} className="btn-check" name="graphData" id="btnradio1" autocomplete="off" checked={isOpenUserGraph} />
                                        <label className="btn btn-outline-primary" for="btnradio1">User</label>

                                        <input type="radio" onChange={() => setOpenGraphData(!isOpenUserGraph)} className="btn-check" name="graphData" id="btnradio2" autocomplete="off" checked={!isOpenUserGraph} />
                                        <label className="btn btn-outline-primary" for="btnradio2">Month</label>
                                    </div>
                                </div>
                                <div className="graph-container-one d-flex">
                                    {(userAnalyticsIsLoading && !isOpenUserGraph) && <XLoader />}
                                    {isOpenUserGraph ? <HorizontalGraph dataPoints={userAnalyticDataPoint} totalNumCases={totalNumCases} graph={true} /> : <VerticalGraph dataPoints={monthlyAnalyticDataPoint} totalNumCases={totalNumCases} graph={true} />}
                                    <div className="d-flex justify-content-between align-items-center w-100 pagination-btm">
                                        <p className="mb-0 text-sm text-default-color">Showing top 10 this month</p>
                                        <div className="graph-actions d-flex align-items-center">
                                            {isOpenDateRange && <a><DateRangePicker onRangeChange={(ranges) => {
                                                setSelectedRange({ ...ranges })
                                            }} /></a>}
                                            {!isOpenDateRange && <a onClick={() => setIsOpenDateRange(!isOpenDateRange)} className="graph-link">Change date range</a>}
                                            <a onClick={() => handleViewAll()} className="graph-link">View All</a>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-5 page-container">
                                <div className="top-content">Average Demand Creation Time:</div>
                                <div className="center-content"><span>{averageTimeTaken}</span> Minutes</div>
                                <div className="bottom-right-text">{totalNumCases} demands</div>
                            </div>
                        </div>
                    </div>
                    <div className="stats-cards db-cards mb-0">
                        <div className="row">
                            <div className="col-md-3">
                                <div className={isActive ? "item flipped" : "item"}>
                                    <div className="inner-content" onClick={() => setIsActive(!isActive)}>
                                        <span className="icon">
                                            <HelpIcon />
                                        </span>
                                        <p className="title">Credit Balance</p>
                                        <span className="count"><span>{cardDetailData?.monthlyRemainingDemand}</span> / {cardDetailData?.demandsPerMonth}</span>
                                    </div>

                                    <div className="back-content" onClick={() => setIsActive(!isActive)}>
                                        <span className="icon">
                                            <HelpIcon />
                                        </span>
                                        <p className="title">Credit Balance</p>
                                        <p className="description">
                                            Your current plan is {cardDetailData?.demandsPerMonth} credits per month. You have {cardDetailData?.monthlyRemainingDemand} credits remaining.
                                        </p>
                                        <a className="link">Upgrade your plan</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={isActiveAccrued ? "item flipped" : "item"}>
                                    <div className="inner-content" onClick={() => setIsActiveAccrued(!isActiveAccrued)}>
                                        <span className="icon">
                                            <HelpIcon />
                                        </span>
                                        <p className="title">Accrued</p>
                                        <span className="count"><span>{cardDetailData?.rollOverCredits}</span> </span>
                                    </div>

                                    <div className="back-content" onClick={() => setIsActiveAccrued(!isActiveAccrued)}>
                                        <span className="icon">
                                            <HelpIcon />
                                        </span>
                                        <p className="title">Accrued</p>
                                        <p className="description">
                                            Accrued credits will be used first before applying next month's credits.                                </p>
                                        <a className="link">Manage your plan</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={isActiveCarte ? "item flipped" : "item"}>
                                    <div className="inner-content" onClick={() => setIsActiveCarte(!isActiveCarte)}>
                                        <span className="icon">
                                            <HelpIcon />
                                        </span>
                                        <p className="title">A La Carte</p>
                                        <span className="count"><span>{cardDetailData?.monthlyAlaCarteDemand}</span> this month</span>
                                    </div>

                                    <div className="back-content" onClick={() => setIsActiveCarte(!isActiveCarte)}>
                                        <span className="icon">
                                            <HelpIcon />
                                        </span>
                                        <p className="title">A La Carte</p>
                                        <p className="description">
                                            Accrued credits will be used first before applying next month's credits.
                                        </p>
                                        <a className="link">Manage your plan</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="item">
                                    <p className="title">Users</p>
                                    <span className="count"><span>{cardDetailData?.totalUsers}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Dashboard;